"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserlyAuthentication = exports.UserlyOtpError = exports.UserlyError = exports.UserlyAuth_DTI_Eventer = exports.UserlyAuth_A08DTI_UserlySettings = exports.UserlyAuth_A02DTI_Forgetpassword = exports.UserlyAuth_A01DTI_IdentityRegister = exports.UserlyAuth_A06DTI_Logout = exports.UserlyAuth_A03DTI_UserlyLogin = exports.UserlyAuth_A04DTI_IdentityLogin = exports.UserlyAuth_A05DTI_SessionLogin = void 0;
const common_1 = require("@userly.mn/auth-dti/common");
const _05_session_login_1 = require("@userly.mn/auth-dti/05.session.login");
Object.defineProperty(exports, "UserlyAuth_A05DTI_SessionLogin", { enumerable: true, get: function () { return _05_session_login_1.UserlyAuth_A05DTI_SessionLogin; } });
const _04_identity_login_1 = require("@userly.mn/auth-dti/04.identity.login");
Object.defineProperty(exports, "UserlyAuth_A04DTI_IdentityLogin", { enumerable: true, get: function () { return _04_identity_login_1.UserlyAuth_A04DTI_IdentityLogin; } });
const _03_userly_login_1 = require("@userly.mn/auth-dti/03.userly.login");
Object.defineProperty(exports, "UserlyAuth_A03DTI_UserlyLogin", { enumerable: true, get: function () { return _03_userly_login_1.UserlyAuth_A03DTI_UserlyLogin; } });
const _06_logout_1 = require("@userly.mn/auth-dti/06.logout");
Object.defineProperty(exports, "UserlyAuth_A06DTI_Logout", { enumerable: true, get: function () { return _06_logout_1.UserlyAuth_A06DTI_Logout; } });
const _07_userly_settings_1 = require("@userly.mn/auth-dti/07.userly.settings");
Object.defineProperty(exports, "UserlyAuth_A08DTI_UserlySettings", { enumerable: true, get: function () { return _07_userly_settings_1.UserlyAuth_A08DTI_UserlySettings; } });
const _01_identity_register_1 = require("@userly.mn/auth-dti/01.identity.register");
Object.defineProperty(exports, "UserlyAuth_A01DTI_IdentityRegister", { enumerable: true, get: function () { return _01_identity_register_1.UserlyAuth_A01DTI_IdentityRegister; } });
const _02_forgetpassword_1 = require("@userly.mn/auth-dti/02.forgetpassword");
Object.defineProperty(exports, "UserlyAuth_A02DTI_Forgetpassword", { enumerable: true, get: function () { return _02_forgetpassword_1.UserlyAuth_A02DTI_Forgetpassword; } });
const eventer_1 = require("@userly.mn/auth-dti/eventer");
Object.defineProperty(exports, "UserlyAuth_DTI_Eventer", { enumerable: true, get: function () { return eventer_1.UserlyAuth_DTI_Eventer; } });
class UserlyError extends Error {
    constructor(code, message) {
        super(message);
        this.code = code;
    }
}
exports.UserlyError = UserlyError;
class UserlyOtpError extends UserlyError {
    constructor(code, message, limit) {
        super(code, message);
        this.code = code;
        this.limit = limit;
    }
}
exports.UserlyOtpError = UserlyOtpError;
function myFetch(baseUrl, routeName, pparam, body) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            let url = baseUrl + (0, common_1.buildUrl)(routeName, pparam);
            let fetchInit = {
                credentials: "include",
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get'
            };
            if (body !== null) {
                fetchInit.method = "post";
                fetchInit.body = JSON.stringify(body);
            }
            let resp = yield fetch(url, fetchInit);
            if (resp.ok) {
                let result = yield resp.json();
                return result;
            }
            else {
                if (resp.json) {
                    let err = yield resp.json();
                    if (err.name && err.message) {
                        throw new UserlyError(err.name, err.message);
                    }
                }
                throw new UserlyError('server.error', resp.statusText);
            }
        }
        catch (error) {
            if (error instanceof TypeError) {
                throw new UserlyError('network', error.message);
            }
            if (error instanceof UserlyError) {
                throw error;
            }
            if (error instanceof Error) {
                throw new UserlyError('error', error.message);
            }
            console.log(error);
            throw new UserlyError('server.error', "cannot error parseing");
        }
    });
}
class UserlyAuthentication {
    constructor(opt) {
        this.opt = opt;
        this._clear = {
            waitEvent1: null
        };
    }
    get userlyDomain() {
        return this.opt.userlyDomain || 'api.userly.mn';
    }
    get baseUrl() {
        return 'https://' + this.userlyDomain + '/api/v5/auth';
    }
    get appdomain() {
        return this.opt.appdomain;
    }
    currentLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.userdata) {
                let expired = this.userdata.loginAt.getTime() + this.userdata.appusertoken[1];
                let now = new Date().getTime();
                if (now > expired) {
                    let userobj = yield this.loginSession();
                    if (userobj) {
                        this.userdata = Object.assign({ loginAt: new Date() }, userobj);
                        return this.userdata;
                    }
                }
                return this.userdata;
            }
            let userobj = yield this.loginSession();
            if (userobj) {
                this.userdata = Object.assign({ loginAt: new Date() }, userobj);
                return this.userdata;
            }
            return null;
        });
    }
    loginIdentity(body) {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain,
            };
            let { userobj } = yield myFetch(this.baseUrl, _04_identity_login_1.UserlyAuth_A04DTI_IdentityLogin.name, pparam, body);
            this.userdata = Object.assign(Object.assign({}, userobj), { loginAt: new Date() });
            return userobj;
        });
    }
    loginSession() {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let { userobj } = yield myFetch(this.baseUrl, _05_session_login_1.UserlyAuth_A05DTI_SessionLogin.name, pparam, null);
            if (userobj) {
                this.userdata = Object.assign(Object.assign({}, userobj), { loginAt: new Date() });
            }
            return userobj;
        });
    }
    loginUserlyRequest() {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let body = {};
            let resp = yield myFetch(this.baseUrl, _03_userly_login_1.UserlyAuth_A03DTI_UserlyLogin.request.name, pparam, body);
            return resp;
        });
    }
    loginUserlySubmit(logindata) {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let body = {
                logindata
            };
            let { userobj } = yield myFetch(this.baseUrl, _03_userly_login_1.UserlyAuth_A03DTI_UserlyLogin.submit.name, pparam, body);
            this.userdata = Object.assign(Object.assign({}, userobj), { loginAt: new Date() });
            return userobj;
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            this.userdata = undefined;
            yield myFetch(this.baseUrl, _06_logout_1.UserlyAuth_A06DTI_Logout.name, pparam, null);
        });
    }
    registerInit() {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let resp = yield myFetch(this.baseUrl, _01_identity_register_1.UserlyAuth_A01DTI_IdentityRegister.initer.name, pparam, null);
            return resp;
        });
    }
    registerIdentityRequest(body) {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let resp = yield myFetch(this.baseUrl, _01_identity_register_1.UserlyAuth_A01DTI_IdentityRegister.request.name, pparam, body);
            return resp;
        });
    }
    registerIdentityDoRegister(body) {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let { userobj } = yield myFetch(this.baseUrl, _01_identity_register_1.UserlyAuth_A01DTI_IdentityRegister.doRegister.name, pparam, body);
            this.userdata = Object.assign(Object.assign({}, userobj), { loginAt: new Date() });
            return userobj;
        });
    }
    registerIdentityDoJoin(body) {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let { userobj } = yield myFetch(this.baseUrl, _01_identity_register_1.UserlyAuth_A01DTI_IdentityRegister.doJoin.name, pparam, body);
            this.userdata = Object.assign(Object.assign({}, userobj), { loginAt: new Date() });
            return userobj;
        });
    }
    forgetpasswordInit() {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let resp = yield myFetch(this.baseUrl, _02_forgetpassword_1.UserlyAuth_A02DTI_Forgetpassword.initer.name, pparam, null);
            return resp;
        });
    }
    forgetpasswordReq(body) {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let resp = yield myFetch(this.baseUrl, _02_forgetpassword_1.UserlyAuth_A02DTI_Forgetpassword.request.name, pparam, body);
            return resp;
        });
    }
    forgetpasswordSubmit(body) {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let { userobj } = yield myFetch(this.baseUrl, _02_forgetpassword_1.UserlyAuth_A02DTI_Forgetpassword.submit.name, pparam, body);
            this.userdata = Object.assign(Object.assign({}, userobj), { loginAt: new Date() });
            return userobj;
        });
    }
    settings() {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let resp = yield myFetch(this.baseUrl, _07_userly_settings_1.UserlyAuth_A08DTI_UserlySettings.get.name, pparam, null);
            return resp;
        });
    }
    settingsPolicy() {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let resp = yield myFetch(this.baseUrl, _07_userly_settings_1.UserlyAuth_A08DTI_UserlySettings.policy.name, pparam, null);
            return resp;
        });
    }
    settingsTerms() {
        return __awaiter(this, void 0, void 0, function* () {
            let pparam = {
                appdomain: this.appdomain
            };
            let resp = yield myFetch(this.baseUrl, _07_userly_settings_1.UserlyAuth_A08DTI_UserlySettings.terms.name, pparam, null);
            return resp;
        });
    }
    wait(waitid, onWaitDone) {
        if (window.EventSource) {
            return this.waitByEventSource({
                waitid
            }, onWaitDone);
        }
        throw new Error("cannnot wait function support");
    }
    waitByEventSource(q, onWaitDone) {
        try {
            let qstr = [];
            if (q.waitid) {
                qstr.push(`waitid=${q.waitid}`);
            }
            let upath = eventer_1.UserlyAuth_DTI_Eventer.path.replace(`:appdomain`, this.appdomain);
            const evtSource = new EventSource(`${this.baseUrl}${upath}?${qstr.join('&')}`);
            const clear = this._clear.waitEvent1 = () => {
                try {
                    this._clear.waitEvent1 = null;
                    evtSource.close();
                }
                catch (error) {
                    console.log('warn evtSource.close', error);
                }
            };
            evtSource.onmessage = (e) => {
                try {
                    let resp = JSON.parse(e.data);
                    if (q.waitid && resp.waitid === q.waitid) {
                        if (resp.flag) {
                            onWaitDone();
                            clear();
                        }
                    }
                }
                catch (error) {
                    console.log(error);
                }
            };
            evtSource.onerror = (err) => {
                clear();
            };
            return () => {
                clear();
            };
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }
    dispose() {
        if (this._clear.waitEvent1) {
            this._clear.waitEvent1();
        }
    }
}
exports.UserlyAuthentication = UserlyAuthentication;
